export type TrustpilotData = {
  score: {
    stars: number;
    trustScore: number;
  };
  numberOfReviews: {
    fiveStars: number;
    fourStars: number;
    oneStar: number;
    threeStars: number;
    total: number;
    twoStars: number;
    usedForTrustScoreCalculation: number;
  };
};

export const getTrustpilotData = async (): Promise<TrustpilotData> => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    cache: 'no-store' as RequestCache
  };
  const result = await fetch(
    `${import.meta.env.PUBLIC_TANGEM_API_URI}ratings/trustpilot`,
    options,
  );
  return await result.json();
};
